import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import imgBeyondScienceCover from "../../assets/images/mission/beyond-science/beyond-science-cover.png"
import imgBeyondScienceCardOne from "../../assets/images/mission/beyond-science/card-img-1.png"
import imgBeyondScienceCardTwo from "../../assets/images/mission/beyond-science/card-img-2.png"
import imgBeyondScienceCardThree from "../../assets/images/mission/beyond-science/card-img-3.png"
import imgBeyondScienceBlackBg from "../../assets/images/mission/beyond-science/card-black-bg.svg"

const BeyondSciencePage = () => (
  <Layout lang="ar">
    <SEO title="Beyond Science" lang="ar" />
    <main>
      <div className="uk-cover-container" uk-height-viewport="offset-top: true; offset-bottom: 40">
        {/* <canvas width="100%" height="100%"></canvas> */}
        <img src={imgBeyondScienceCover} alt="" style={{transform: "translate(50%,-50%)"}} data-uk-cover />
        <div className="uk-overlay uk-position-cover header-overlay"></div>
      </div>
      <section id="about-emm" style={{position: "relative"}}>
          <div className="large-container pb6 ph4">
              <div className="uk-text-center">
                  <h1 className="uk-heading-large uk-text-bold uk-visible@s beyond-science font-nostromo" style={{marginTop: "-80px"}}>
                  الأهداف
                      <span className="text-highlight-red mr4">
                      الاستراتيجية                      
                      </span>
                  </h1>
                  <h1 className="uk-heading-large uk-text-bold uk-hidden@s font-nostromo" style={{marginTop: "-70px", fontFamily: "NostromoRegular"}}>
                  الأهداف 
                   <br/>
                      <span className="text-highlight-red"> 
                      الاستراتيجية 
                      </span>
                  </h1>
                  <div className="w-80-ns" style={{margin: "auto"}}>
                    <p className="mv4 text-light uk-text-center">
                    بجانب الأهداف العلمية لمشروع الإمارات لاستكشاف المريخ، هناك أهداف استراتيجية أخرى تدور حول المعرفة البشرية والقدرات الإماراتية والتعاون الدولي. إليكم نظرة على الأهداف التي سيساعدنا مشروع الإمارات لاستكشاف المريخ على تحقيقها بجانب البيانات التي سيجمعها والنتائج العلمية التي سيحققها:
                    </p>
                  </div>     
              </div>
              <div className="standard-container standard-bg">
                  <div className="grid-col-3">
                    <div>
                        <div className="uk-inline standard-bg ma1">
                            <img src={imgBeyondScienceCardOne} alt=""/>
                            <div className="uk-overlay-primary uk-position-cover"></div>
                            <div className="uk-overlay uk-position-top uk-text-center uk-light">
                                <h3>
                                تحسين جودة
                                </h3>
                                <div className="heading-underline-light" style={{margin: "auto"}}></div>
                                <p className="text-light">تحسين جودة الحياة على الأرض من خلال بذل كامل جهدنا لتحقيق اكتشافات جديدة</p>
                            </div>
                        </div> 
                        <div className="uk-inline standard-bg ma1">
                            <img src={imgBeyondScienceBlackBg} style={{height: "400px", backgroundColor: "black"}} alt=""/>
                            <div className="uk-overlay-primary uk-position-cover"></div>
                            <div className="uk-overlay uk-position-top uk-text-center uk-light">
                                <h3>
                                ترسيخ مكانة الإمارات في المنطقة</h3>
                                <div className="heading-underline-light" style={{margin: "auto"}}></div>
                                <p className="text-light">ترسيخ مكانة الإمارات كمنارة للتقدم في المنطقة</p>
                            </div>
                        </div>                            
                    </div>                               
                      <div>
                        <div className="uk-inline ma1" style={{backgroundColor: "black"}}>
                            <img src={imgBeyondScienceBlackBg} style={{height: "240px", width: "100%"}} alt=""/>
                            <div className="uk-overlay-primary uk-position-cover"></div>
                            <div className="uk-overlay uk-position-top uk-text-center uk-light">
                                <h3>
                                تشجيع
                                </h3>
                                <div className="heading-underline-light" style={{margin: "auto"}}></div>
                                <p className="text-light">تشجيع التعاون الدولي فيما يتعلق باستكشاف كوكب المريخ</p>
                            </div>
                        </div>
                        <div className="uk-inline standard-bg ma1">
                            <img src={imgBeyondScienceCardTwo} alt=""/>
                            <div className="uk-overlay-primary uk-position-cover"></div>
                            <div className="uk-overlay uk-position-top uk-text-center uk-light">
                                <h3>
                                رفع مستوى الكفاءات الإماراتية
                                </h3>
                                <div className="heading-underline-light" style={{margin: "auto"}}></div>
                                <p className="text-light">رفع مستوى الكفاءات الإماراتية في مجال استكشاف الكواكب الأخرى</p>
                            </div>
                        </div>
                        <div className="uk-inline ma1" style={{backgroundColor: "black"}}>
                            <img src={imgBeyondScienceBlackBg} style={{height: "235px", width: "100%"}} alt=""/>
                            <div className="uk-overlay-primary uk-position-cover"></div>
                            <div className="uk-overlay uk-position-top uk-text-center uk-light">
                                <h3>
                                إلهام الأجيال
                                </h3>
                                <div className="heading-underline-light" style={{margin: "auto"}}></div>
                                <p className="text-light">إلهام الأجيال العربية الناشئة وتشجيعهم على دراسة علوم الفضاء</p>
                            </div>
                        </div>
                      </div>
                      <div>
                        <div className="uk-inline standard-bg ma1">
                            <img src={imgBeyondScienceCardThree} alt=""/>
                            <div className="uk-overlay-primary uk-position-cover"></div>
                            <div className="uk-overlay uk-position-top uk-text-center uk-light">
                                <h3>
                                لنكون دولة عالمية رائدة
                                </h3>
                                <div className="heading-underline-light" style={{margin: "auto"}}></div>
                                <p className="text-light">لنكون دولة عالمية رائدة في مجال أبحاث الفضاء</p>
                            </div>
                        </div>
                        <div className="uk-inline standard-bg ma1">
                            <img src={imgBeyondScienceBlackBg} style={{height: "400px", backgroundColor: "black"}} alt=""/>
                            <div className="uk-overlay-primary uk-position-cover"></div>
                            <div className="uk-overlay uk-position-top uk-text-center uk-light">
                                <h3>
                                بناء المعرفة العلمية                                
                                </h3>
                                <div className="heading-underline-light" style={{margin: "auto"}}></div>
                                <p className="text-light">
                                بناء المعرفة العلمية لأنه من المحتمل أن الاقتصاد المستدام في المستقبل سيكون اقتصاداً قائماً على المعرفة
                                </p>
                            </div>
                        </div>
                      </div>                    
                  </div>
              </div>
          </div>  
      </section>
    </main>
  </Layout>
)

export default BeyondSciencePage
